import React from 'react';

import Projects from '../Components/Projects/Projects';
import '../Style/index.scss';
import '../mixins.scss';
import '../variables.scss';
import '../Components/Projects/Projects.scss';

const ProjectPage = () => {
    return(
        <div className="container">
            <Projects />
        </div>
    );
}

export default ProjectPage;