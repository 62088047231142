import React from 'react';

import DesignGuide from '../Components/DesignGuide/DesignGuide';
import '../Style/index.scss';
import '../mixins.scss';
import '../variables.scss';
import '../Components/DesignGuide/DesignGuide.scss';

const DesignGuidePage = () => {
    return(
        <div className="container">
            <DesignGuide />
        </div>
    );
}

export default DesignGuidePage;